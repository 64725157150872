// @flow
import type { RegisteredCache, SerializedStyles, PrettyLightsCache } from '../types';

const isBrowser = typeof document !== 'undefined';

export function getRegisteredStyles(
  registered: RegisteredCache,
  registeredStyles: string[],
  classNames: string
) {
  let rawClassName = '';

  classNames.split(' ').forEach(className => {
    if (registered[className] !== undefined) {
      registeredStyles.push(registered[className]);
      // $FORK$ Emotion 10 does not check for truthiness of className.
      // without checking, rawClassName can result in a leading space when
      // an empty string is passed - better to return an empty string.
    } else if (className) {
      rawClassName += `${className} `;
    }
  });
  return rawClassName;
}

// eslint-disable-next-line consistent-return
export const insertStyles = (cache: PrettyLightsCache, serialized: SerializedStyles) => {
  const className = `${cache.key}-${serialized.name}`;
  // $FORK$ registered cache always needs to be set
  if (cache.registered[className] === undefined) {
    cache.registered[className] = serialized.styles;
  }

  if (cache.inserted[serialized.name] === undefined) {
    let stylesForSSR = '';
    let current = serialized;
    do {
      const maybeStyles = cache.insert(`.${className}`, current, cache.sheet, true);
      if (!isBrowser && maybeStyles !== undefined) {
        stylesForSSR += maybeStyles;
      }
      current = current.next;
    } while (current !== undefined);
    // $INFO$ this is for auto-SSR in styled (which we don't use), leaving it here to avoid churn
    if (!isBrowser && stylesForSSR.length !== 0) {
      return stylesForSSR;
    }
  }
};
