// @flow
import * as React from 'react';
import { insertStyles } from '../utils';
import { serializeStyles } from '../serialize';
import StyleSheet from '../sheet';
import { type PrettyLights, type GlobalProps, type InnerGlobalProps } from '../types';
import { ThemeContext } from '../context';

const isBrowser = typeof document !== 'undefined';

// $INFO$ this is a fork of `Global` from `@emotion/core`, which is different than `emotion`

// $FORK$ we don't support the `css` prop, so remove all mention of it

// $FORK$ we avoid using the withEmotionCache HoC by using this wrapper
function createGlobal(lights: PrettyLights) {
  const Global: React.StatelessFunctionalComponent<GlobalProps> =
    /* #__PURE__ */
    (props: GlobalProps) => {
      const { styles } = props;

      if (typeof styles === 'function') {
        return (
          <ThemeContext.Consumer>
            {theme => {
              // $FORK$ pass the registered cache
              const serialized = serializeStyles([styles(theme)], lights.cache.registered);

              return <InnerGlobal serialized={serialized} cache={lights.cache} />;
            }}
          </ThemeContext.Consumer>
        );
      }
      // $FORK$ pass the registered cache
      const serialized = serializeStyles([styles], lights.cache.registered);

      return <InnerGlobal serialized={serialized} cache={lights.cache} />;
    };

  // maintain place over rerenders.
  // initial render from browser, insertBefore context.sheet.tags[0] or if a style hasn't been inserted there yet, appendChild
  // initial client-side render from SSR, use place of hydrating tag

  class InnerGlobal extends React.Component<InnerGlobalProps> {
    // $FlowFixMe
    sheet: StyleSheet;

    componentDidMount() {
      // $FlowFixMe
      this.sheet = new StyleSheet({
        key: `${this.props.cache.key}-global`,
        nonce: this.props.cache.sheet.nonce,
        container: this.props.cache.sheet.container,
      });
      // $FlowFixMe
      const node: HTMLStyleElement | null = document.querySelector(
        `style[data-lights-${this.props.cache.key}="${this.props.serialized.name}"]`
      );

      if (node !== null) {
        this.sheet.tags.push(node);
      }
      if (this.props.cache.sheet.tags.length) {
        // eslint-disable-next-line prefer-destructuring
        this.sheet.before = this.props.cache.sheet.tags[0];
      }
      this.insertStyles();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.serialized.name !== this.props.serialized.name) {
        this.insertStyles();
      }
    }

    insertStyles() {
      if (this.props.serialized.next !== undefined) {
        // insert keyframes
        insertStyles(this.props.cache, this.props.serialized.next);
      }
      if (this.sheet.tags.length) {
        // if this doesn't exist then it will be null so the style element will be appended
        const element = this.sheet.tags[this.sheet.tags.length - 1].nextElementSibling;
        this.sheet.before = ((element: any): Element | null);
        this.sheet.flush();
      }

      // $FlowFixMe
      this.props.cache.insert(``, this.props.serialized, this.sheet, false);
    }

    componentWillUnmount() {
      this.sheet.flush();
    }

    render() {
      if (!isBrowser) {
        const { serialized } = this.props;

        let serializedNames = serialized.name;
        let serializedStyles = serialized.styles;
        let { next } = serialized;
        while (next !== undefined) {
          serializedNames += ` ${next.name}`;
          serializedStyles += next.styles;
          next = next.next;
        }

        // $FlowFixMe
        this.props.cache.insert(
          ``,
          { name: serializedNames, styles: serializedStyles },
          // $FlowFixMe
          this.sheet,
          true
        );

        const styleProps = {
          [`data-global-${this.props.cache.key}`]: serializedNames,
        };

        return React.createElement('style', styleProps);
      }

      return null;
    }
  }
  return Global;
}

export default createGlobal;
