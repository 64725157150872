import create from './create';
import createGlobal from './global';
import createStyled from './styled';
import tags from './styled/tags';
import { ThemeContext } from './context';
import { ThemeProvider, useTheme, withTheme, themeFn } from './theming';

const context = typeof global !== 'undefined' ? global : {};
const lights = create(context);

const Global = createGlobal(lights);

const baseStyled = createStyled(lights);

// bind it to avoid mutating the original function
const styled = baseStyled.bind();

tags.forEach(tagName => {
  styled[tagName] = styled(tagName);
});

const {
  flush,
  hydrate,
  cx,
  merge,
  getRegisteredStyles,
  injectGlobal,
  keyframes,
  css,
  sheet,
  cache,
} = lights;

export {
  flush,
  hydrate,
  cx,
  merge,
  getRegisteredStyles,
  injectGlobal,
  keyframes,
  css,
  sheet,
  cache,
  ThemeContext,
  ThemeProvider,
  useTheme,
  withTheme,
  themeFn,
  styled,
  Global,
};
