// @flow
import * as React from 'react';
import type { ElementType } from 'react';
import type {
  PrettyLights,
  CreateStyled,
  StyledOptions,
  PrivateStyledComponent,
  PrivateStyledComponentProps,
} from '../types';
import { ThemeContext } from '../context';
import { ILLEGAL_ESCAPE_SEQUENCE_ERROR, serializeStyles } from '../serialize';
import { getRegisteredStyles, insertStyles } from '../utils';
import { getDefaultShouldForwardProp } from './utils';

/* eslint-disable no-underscore-dangle,camelcase,react/prop-types */

// $FORK$ we avoid using the withEmotionCache HoC by using this wrapper
// func from Emotion 9
function createPrettyLightsStyled(lights: PrettyLights) {
  // $FORK$ Emotion 10 starts right here
  const createStyled: CreateStyled = (tag: any, options?: StyledOptions) => {
    if (process.env.NODE_ENV !== 'production') {
      if (tag === undefined) {
        throw new Error(
          'You are trying to create a styled element with an undefined component.\nYou may have forgotten to import it.'
        );
      }
    }
    let identifierName;
    let shouldForwardProp;
    let targetClassName;
    if (options !== undefined) {
      identifierName = options.label;
      targetClassName = options.target;
      shouldForwardProp =
        tag.__lights_forwardProp && options.shouldForwardProp
          ? propName =>
              tag.__lights_forwardProp(propName) &&
              // $FlowFixMe
              options.shouldForwardProp(propName)
          : options.shouldForwardProp;
    }
    const isReal = tag.__lights_real === tag;
    const baseTag = (isReal && tag.__lights_base) || tag;

    if (typeof shouldForwardProp !== 'function' && isReal) {
      shouldForwardProp = tag.__lights_forwardProp;
    }
    const defaultShouldForwardProp = shouldForwardProp || getDefaultShouldForwardProp(baseTag);
    const shouldUseAs = !defaultShouldForwardProp('as');

    return function styled<P>(...args): PrivateStyledComponent<P> {
      const styles =
        isReal && tag.__lights_styles !== undefined ? tag.__lights_styles.slice(0) : [];

      if (identifierName !== undefined) {
        styles.push(`label:${identifierName};`);
      }
      if (args[0] == null || args[0].raw === undefined) {
        // eslint-disable-next-line prefer-spread
        styles.push.apply(styles, args);
      } else {
        if (process.env.NODE_ENV !== 'production' && args[0][0] === undefined) {
          console.error(ILLEGAL_ESCAPE_SEQUENCE_ERROR);
        }
        styles.push(args[0][0]);
        const len = args.length;
        let i = 1;
        for (; i < len; i += 1) {
          if (process.env.NODE_ENV !== 'production' && args[0][i] === undefined) {
            console.error(ILLEGAL_ESCAPE_SEQUENCE_ERROR);
          }
          styles.push(args[i], args[0][i]);
        }
      }

      // $FlowFixMe
      const Styled: PrivateStyledComponent<P> = (props: PrivateStyledComponentProps) => (
        <ThemeContext.Consumer>
          {theme => {
            const finalTag = (shouldUseAs && props.as) || baseTag;

            let className = '';
            const classInterpolations = [];
            let mergedProps = props;
            if (props.theme == null) {
              mergedProps = {};
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              for (const key in props) {
                mergedProps[key] = props[key];
              }
              mergedProps.theme = theme;
            }

            if (typeof props.className === 'string') {
              className = getRegisteredStyles(
                lights.cache.registered,
                classInterpolations,
                props.className
              );
            } else if (props.className != null) {
              // $FlowFixMe
              className = `${props.className} `;
            }

            const serialized = serializeStyles(
              styles.concat(classInterpolations),
              lights.cache.registered,
              mergedProps
            );

            // $FORK$ we don't return styles here because we don't do auto-SSR
            insertStyles(lights.cache, serialized /* , typeof finalTag === 'string' */);

            className += `${lights.cache.key}-${serialized.name}`;
            if (targetClassName !== undefined) {
              className += ` ${targetClassName}`;
            }

            const finalShouldForwardProp =
              shouldUseAs && shouldForwardProp === undefined
                ? getDefaultShouldForwardProp(finalTag)
                : defaultShouldForwardProp;

            const newProps = {};

            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const key in props) {
              // eslint-disable-next-line no-continue
              if (shouldUseAs && key === 'as') continue;

              if (
                // $FlowFixMe
                finalShouldForwardProp(key)
              ) {
                newProps[key] = props[key];
              }
            }

            newProps.className = className;

            // $FORK$ handle ref properly $TODO$
            newProps.ref = props.innerRef;

            return React.createElement(finalTag, newProps);
          }}
        </ThemeContext.Consumer>
      );

      Styled.displayName =
        identifierName !== undefined
          ? identifierName
          : `Styled(${
              typeof baseTag === 'string'
                ? baseTag
                : baseTag.displayName || baseTag.name || 'Component'
            })`;

      if (tag.defaultProps !== undefined) {
        // $FlowFixMe
        Styled.defaultProps = tag.defaultProps;
      }
      Styled.__lights_styles = styles;
      Styled.__lights_base = baseTag;
      Styled.__lights_real = Styled;
      Styled.__lights_forwardProp = shouldForwardProp;

      Object.defineProperty(Styled, 'toString', {
        value() {
          if (targetClassName === undefined && process.env.NODE_ENV !== 'production') {
            return 'NO_COMPONENT_SELECTOR';
          }
          // $FlowFixMe: coerce undefined to string
          return `.${targetClassName}`;
        },
      });

      Styled.withComponent = (nextTag: ElementType, nextOptions?: StyledOptions) => {
        return createStyled(
          nextTag,
          nextOptions !== undefined ? { ...(options || {}), ...nextOptions } : options
        )(...styles);
      };

      return Styled;
    };
  };

  return createStyled;
}

export default createPrettyLightsStyled;
