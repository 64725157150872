// @flow
import createCache from '../cache';
import { serializeStyles } from '../serialize';
import { insertStyles, getRegisteredStyles } from '../utils';
import type { SerializedStyles, PrettyLights } from '../types';

/* eslint-disable no-underscore-dangle,consistent-return */

function insertWithoutScoping(cache, serialized: SerializedStyles, cacheGlobal: boolean) {
  if (cache.inserted[serialized.name] === undefined) {
    return cache.insert('', serialized, cache.sheet, true, cacheGlobal);
  }
}

function merge(registered: Object, css: *, className: string) {
  const registeredStyles = [];

  const rawClassName = getRegisteredStyles(registered, registeredStyles, className);

  if (registeredStyles.length < 2) {
    return className;
  }
  return rawClassName + css(registeredStyles);
}

// $FORK$ types are declared in ../types.js so they can be shared
// Emotion spreads types and functionality across several packages

// $FORK$ "context" does not exist in Emotion 10
function create(context: any, options: *): PrettyLights {
  if (context.__SECRET_LIGHTS__ !== undefined) {
    return context.__SECRET_LIGHTS__;
  }

  const cache = createCache(options);

  // $FlowFixMe
  cache.sheet.speedy = function speedy(value: boolean) {
    if (process.env.NODE_ENV !== 'production' && this.ctr !== 0) {
      throw new Error('speedy must be changed before any rules are inserted');
    }
    this.isSpeedy = value;
  };
  cache.compat = true;

  const css = (...args) => {
    const serialized = serializeStyles(args, cache.registered);
    const selector = `${cache.key}-${serialized.name}`;
    insertStyles(cache, serialized);
    return selector;
  };

  const keyframes = (...args) => {
    const serialized = serializeStyles(args, cache.registered);
    const animation = `animation-${serialized.name}`;
    insertWithoutScoping(cache, {
      name: serialized.name,
      styles: `@keyframes ${animation}{${serialized.styles}}`,
    });

    return animation;
  };

  const injectGlobal = (...args) => {
    const serialized = serializeStyles(args, cache.registered);
    insertWithoutScoping(cache, serialized, true);
  };

  const cx = (...args) => {
    // eslint-disable-next-line no-use-before-define
    return merge(cache.registered, css, classnames(args));
  };

  const lights = {
    css,
    cx,
    injectGlobal,
    keyframes,
    hydrate(ids: Array<string>) {
      ids.forEach(key => {
        cache.inserted[key] = true;
      });
    },
    flush() {
      cache.registered = {};
      cache.inserted = {};
      cache.global = {};
      cache.sheet.flush();
    },
    // $FlowFixMe
    sheet: cache.sheet,
    cache,
    getRegisteredStyles,
    merge: merge.bind(null, cache.registered, css),
  };

  // $FORK$ "context" does not exist in Emotion 10
  context.__SECRET_LIGHTS__ = lights;
  return lights;
}

const classnames = args => {
  let cls = '';
  for (let i = 0; i < args.length; i += 1) {
    const arg = args[i];
    // eslint-disable-next-line no-continue
    if (arg == null) continue;

    let toAdd;
    switch (typeof arg) {
      case 'boolean':
        break;
      case 'object': {
        if (Array.isArray(arg)) {
          toAdd = classnames(arg);
        } else {
          toAdd = '';
          // eslint-disable-next-line no-restricted-syntax
          for (const k in arg) {
            if (arg[k] && k) {
              if (toAdd) {
                toAdd += ' ';
              }
              toAdd += k;
            }
          }
        }
        break;
      }
      default: {
        toAdd = arg;
      }
    }
    if (toAdd) {
      if (cls) {
        cls += ' ';
      }
      cls += toAdd;
    }
  }
  return cls;
};

export default create;
