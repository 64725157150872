// @flow
import Stylis from '@emotion/stylis';
import weakMemoize from '@emotion/weak-memoize';
import StyleSheet from '../sheet';
import { Sheet, ruleSheet, removeLabel } from './stylis-plugins';
import type {
  PrettyLightsCache,
  SerializedStyles,
  StylisPlugin,
  StyleSheet as StyleSheetType,
} from '../types';

const isBrowser = typeof document !== 'undefined';

export type PrefixOption = boolean | ((key: string, value: string, context: 1 | 2 | 3) => boolean);
type StylisPlugins = StylisPlugin[] | StylisPlugin;

export type Options = {
  nonce?: string,
  stylisPlugins?: StylisPlugins,
  prefix?: PrefixOption,
  key?: string,
  container?: HTMLElement,
  speedy?: boolean,
};

// $FORK$ Since styles added to the cache are raw, we must remove the Stylis
// delimiter manually. This is because Emotion 10 doesn't set actual styles as
// values in the `inserted` cache
const delimiterRegEx = /\/\*\|\*\//g;

const rootServerStylisCache = {};

const getServerStylisCache = isBrowser
  ? undefined
  : weakMemoize(() => {
      const getCache = weakMemoize(() => ({}));
      const prefixTrueCache = {};
      const prefixFalseCache = {};
      return prefix => {
        if (prefix === undefined || prefix === true) {
          return prefixTrueCache;
        }
        if (prefix === false) {
          return prefixFalseCache;
        }
        return getCache(prefix);
      };
    });

const createCache = (options?: Options): PrettyLightsCache => {
  if (options === undefined) options = {};
  const key = options.key || 'css';
  // $FORK$ we bail earlier, Emotion 10 bails after making an instance of Stylis :shrug:
  if (process.env.NODE_ENV !== 'production') {
    if (/[^a-z-]/.test(key)) {
      throw new Error(
        `Pretty Lights key must only contain lower case alphabetical characters and - but "${key}" was passed`
      );
    }
  }

  let cache: PrettyLightsCache;

  let stylisOptions;
  if (options.prefix !== undefined) {
    stylisOptions = {
      prefix: options.prefix,
    };
  }

  const stylis = new Stylis(stylisOptions);

  const inserted = {};
  // $FlowFixMe
  let container: HTMLElement | null;
  if (isBrowser) {
    container = options.container || document.head;

    // $FORK$ set variable for attribute
    const attr = `data-lights-${key}`;
    const nodes = document.querySelectorAll(`style[${attr}]`);

    Array.prototype.forEach.call(nodes, (node: HTMLStyleElement) => {
      const attrib = node.getAttribute(attr) || '';
      // $FlowFixMe
      attrib.split(' ').forEach(id => {
        inserted[id] = true;
      });
      if (node.parentNode !== container) {
        // $FlowFixMe
        container.appendChild(node);
      }
    });
  }

  let insert: (
    selector: string,
    serialized: SerializedStyles,
    sheet: StyleSheetType,
    shouldCache: boolean
  ) => string | void;

  if (isBrowser) {
    stylis.use(options.stylisPlugins)(ruleSheet);

    insert = (
      selector: string,
      serialized: SerializedStyles,
      // $FlowFixMe
      sheet: StyleSheetType,
      shouldCache: boolean,
      cacheGlobal: boolean
    ) => {
      Sheet.current = sheet;
      if (process.env.NODE_ENV !== 'production' && serialized.map !== undefined) {
        const { map } = serialized;
        Sheet.current = {
          insert: (rule: string) => {
            sheet.insert(rule + map);
          },
        };
      }
      // $FORK$ Emotion 10 does not check for inserted entry
      if (cache.inserted[serialized.name] === undefined) {
        // $FORK$ Emotion 10 sets the inserted cache to `true`
        // delimiterRegEx might work better as split().join() $TODO$ benchmark
        const rules = stylis(selector, serialized.styles);
        if (shouldCache) {
          cache.inserted[serialized.name] = rules.replace(delimiterRegEx, '');
          if (selector === '' && cacheGlobal) {
            cache.global[serialized.name] = true;
          }
        }
      }
    };
  } else {
    stylis.use(removeLabel);
    let serverStylisCache = rootServerStylisCache;
    if (options.stylisPlugins || options.prefix !== undefined) {
      stylis.use(options.stylisPlugins);
      // $FlowFixMe
      serverStylisCache = getServerStylisCache(options.stylisPlugins || rootServerStylisCache)(
        options.prefix
      );
    }
    const getRules = (selector: string, serialized: SerializedStyles): string => {
      const { name } = serialized;
      if (serverStylisCache[name] === undefined) {
        serverStylisCache[name] = stylis(selector, serialized.styles);
      }
      return serverStylisCache[name];
    };

    insert = (
      selector: string,
      serialized: SerializedStyles,
      // $FlowFixMe
      sheet: StyleSheetType,
      shouldCache: boolean,
      cacheGlobal: boolean
      // eslint-disable-next-line consistent-return
    ) => {
      // $FORK$ we check for inserted[name] so that styles aren't returned
      // multiple times on the server
      if (cache.inserted[serialized.name] === undefined) {
        const rules = getRules(selector, serialized);

        // $FORK$ we don't need to handle the case where cache.compat is
        // not equal to true. The difference in Emotion 10 is that `inserted[name]`
        // is set to `true` instead of styles.

        if (shouldCache) {
          cache.inserted[serialized.name] = rules;
          if (selector === '' && cacheGlobal) {
            cache.global[serialized.name] = true;
          }
        } else {
          return rules;
        }
      }
    };
  }

  if (process.env.NODE_ENV !== 'production') {
    // https://esbench.com/bench/5bf7371a4cd7e6009ef61d0a
    const commentStart = /\/\*/g;
    const commentEnd = /\*\//g;

    stylis.use((context, content) => {
      switch (context) {
        default:
          break;
        case -1: {
          while (commentStart.test(content)) {
            commentEnd.lastIndex = commentStart.lastIndex;

            if (commentEnd.test(content)) {
              commentStart.lastIndex = commentEnd.lastIndex;
              // eslint-disable-next-line no-continue
              continue;
            }

            throw new Error(
              'Your styles have an unterminated comment ("/*" without corresponding "*/").'
            );
          }

          commentStart.lastIndex = 0;
          break;
        }
      }
    });

    // $FORK$ we don't need the check for pseudo selectors since we don't use Emotion 10 auto-SSR
  }

  cache = {
    key,
    // $FlowFixMe
    sheet: new StyleSheet({
      key,
      // $FlowFixMe
      container,
      nonce: options.nonce,
      speedy: options.speedy,
    }),
    nonce: options.nonce,
    inserted,
    registered: {},
    global: {},
    insert,
  };

  // $FlowFixMe
  return cache;
};

export default createCache;
